export const Routes = {
  usersPath: () => `users/`,
  userLoginPath: () => `users/login/`,
  userPath: (id) => `users/${id}/`,
  userLinkProviderPath: (id) => `users/${id}/link_provider/`,
  tagsPath: () => `tags/`,
  tagPath: (id) => `tags/${id}/`,
  tagAddAudiosPath: () => `tags/add_audios/`,
  tagAddFoldersPath: () => `tags/add_folders/`,
  tagRemoveAudiosPath: () => `tags/remove_audios/`,
  tagRemoveFoldersPath: () => `tags/remove_folders/`,
  foldersPath: () => `folders/`,
  folderPath: (id) => `folders/${id}/`,
  folderAddAudiosPath: (id) => `folders/${id}/add_audios/`,
  folderRemoveAudiosPath: () => `folders/remove_audios/`,
  folderRemovePath: () => `folders/remove/`,
  folderRestorePath: () => `folders/restore/`,
  audiosPath: () => `audios/`,
  audiosReSubmitPath: (id) => `audios/${id}/re_submit/`,
  audiosRemovePath: () => `audios/remove/`,
  audiosSummarizePath: (id) => `audios/${id}/summarize1/`,
  audiosTranscribeRemainingPath: (id) => `audios/${id}/transcribe_remaining/`,
  aiServiceRatePath: (audioId) => `audios/${audioId}/ai_service_rate2/`,
  audiosRestorePath: () => `audios/restore/`,
  audiosGenerateSignedUrlPath: () => `audios/generate_signed_url/`,
  audioPath: (id) => `audios/${id}/`,
  exportFilePath: (id) => `audios/${id}/export_file/`,
  audioUUIDPath: () => `audios/code/`,
  segmentPath: (id) => `segments/${id}/`,
  invoicesPath: () => `invoices/`,
  invoicePath: (id) => `invoices/${id}/`,
  purchasesPath: () => `purchases/`,
  purchasePath: (id) => `purchases/${id}/`,
  productsPath: () => `products/`,
  purchaseStripeCheckoutPath: () => `purchases/stripe_checkout/`,
  purchaseRedeemPath: () => `purchases/redeem/`,
  purchaseStripeCancel: (id) => `purchases/${id}/stripe_cancel/`,
  purchaseStripeResume: (id) => `purchases/${id}/stripe_resume/`,
  productPath: (id) => `products/${id}/`,
  creditUsagesPath: () => `credit-usages/`,
  messagesPath: () => `messages/`,
  messageResponsePath: (messageId) => `messages/${messageId}/response/`,
}
