export const de = {
  sign_in: 'Anmelden',
  email: 'E-Mail',
  password: 'Passwort',
  i_agree_to_toc: 'Ich stimme den Nutzungsbedingungen zu.',
  terms_and_conditions: 'Nutzungsbedingungen',
  privacy_policy: 'Datenschutzrichtlinie',
  already_have_an_account: 'Haben Sie bereits ein Konto?',
  sign_up: 'Registrieren',
  dont_have_an_account: 'Sie haben noch kein Konto?',
  forgot_password: 'Passwort vergessen?',
  email_already_in_use: 'E-Mail bereits in Verwendung',
  weak_password: 'Schwaches Passwort',
  too_many_attempts: 'Zu viele Versuche',
  invalid_email_or_password: 'Ungültige E-Mail oder Passwort',
  user_already_registered: 'Benutzer bereits registriert',
  unexpected_error: 'Unerwarteter Fehler',
  audios: 'Audios',
  name: 'Name',
  auto: 'Automatisch',
  auto_detect: 'Automatische Erkennung',
  speaker_num: 'Anzahl der Sprecher',
  speaker_num_reason:
    'Wenn Sie uns die Anzahl der Sprecher mitteilen, erhalten Sie ein besseres Transkriptionsergebnis.',
  audio_language: 'Audiosprache',
  your_email: 'Ihre E-Mail',
  email_result: 'Wir senden Ihnen eine E-Mail mit dem Transkriptionsergebnis.',
  provide_language:
    'Wenn Sie uns die Audiosprache mitteilen, erhalten Sie ein besseres Transkriptionsergebnis.',
  upload_audio: 'Audio hochladen',
  upload_file: 'Datei hochladen',
  process_audio: 'Ihre Datei wird verarbeitet',
  process_audio_des:
    'Bitte warten Sie, während Ihre Audioaufnahme transkribiert wird. Dies kann einige Minuten dauern.',
  uploading: 'Hochladen',
  pending: 'Ausstehend',
  uploaded: 'Hochgeladen',
  failed: 'Fehlgeschlagen',
  status: 'Status',
  language: 'Sprache',
  speakers: 'Sprecher',
  duration: 'Dauer',
  done: 'Fertig',
  close: 'Schließen',
  queuing: 'In der Warteschlange',
  created_at: 'Erstellt am',
  records: 'Aufzeichnungen',
  transcribe: 'Transkribieren',
  delete: 'Löschen',
  processing: 'Verarbeiten',
  from_youtube: 'Von Youtube',
  upload_files: 'Dateien hochladen',
  place_youtube_link_here: 'Youtube-Link hier einfügen',
  submit: 'Senden',
  email_notification:
    'Die Bearbeitungszeit hängt von der Länge und Komplexität der Audiodatei ab. In der Regel erhalten Sie Ihre Transkription innerhalb weniger Stunden. Wir benachrichtigen Sie per E-Mail, sobald sie fertig ist.',
  filters: 'Filter',
  filter: 'Filter',
  replace: 'Ersetzen',
  share_and_export: 'Teilen und exportieren',
  replace_all: 'Alle ersetzen',
  next: 'Weiter',
  previous: 'Vorherige',
  find: 'Suchen',
  replace_with: 'Ersetzen durch',
  x_of_x: '{current} von {total}',
  scroll_lock: 'Scroll-Sperre',
  tags: 'Tags',
  bookmark: 'Lesezeichen',
  audio: 'Audio',
  cancel: 'Abbrechen',
  export: 'Exportieren',
  export_format: 'Exportformat',
  copy_text: 'Text kopieren',
  monologue: 'Monolog',
  include_timestamps: 'Zeitstempel einbeziehen',
  include_speaker_names: 'Sprechername einbeziehen',
  combine_same_speaker: 'Gleiche Sprechersegmente zusammenführen',
  export_mode: 'Exportmodus',
  no_preview: 'Keine Vorschau',
  copy: 'Kopieren',
  copied: 'Kopiert',
  unlock_pro_feature:
    'Um diese Funktion freizuschalten, wechseln Sie zum "Pro"-Abonnementplan.',
  pro_features: 'Pro-Funktionen',
  pro_minutes: '1800 Minuten / monat',
  pro_interact_with_audio: 'Mit Audio interagieren',
  pro_interact_with_audio_des: 'Fragen Sie Ihr Audio an',
  pro_summary: 'KI-Zusammenfassung',
  pro_summary_des: 'Fassen Sie Ihre Audioaufnahme zusammen',
  pro_priority: 'Prioritätswarteschlange',
  pro_priority_des: 'Mit Priorität verarbeitetes Audio',
  pro_multi_speakers: 'Erkennung mehrerer Sprecher',
  pro_multi_speakers_des: 'Erkennt und benennt unterschiedliche Sprecher genau',
  redirect_to_stripe:
    'Sie werden zu Stripe weitergeleitet, um die Zahlung abzuschließen.',
  x_per_month: '{x} / Monat',
  x_per_year: '{x} / Jahr',
  trash: 'Papierkorb',
  dashboard: 'Dashboard',
  logout: 'Abmelden',
  are_you_sure_to_delete:
    'Sind Sie sicher, dass Sie ausgewählte Aufzeichnungen löschen möchten?',
  restore: 'Wiederherstellen',
  are_you_sure_to_restore:
    'Sind Sie sicher, dass Sie ausgewählte Aufzeichnungen wiederherstellen möchten?',
  deleted_at: 'Gelöscht am',
  free_member_limited_view:
    'Kostenlose Mitglieder haben nur Zugriff auf die ersten {x} Minuten des Transkriptionsergebnisses.',
  hidden_segment: '{x} Segmente werden nach {y} Minuten ausgeblendet.',
  settings: 'Einstellungen',
  subscription: 'Abonnement',
  display_language: 'Benutzeroberflächensprache',
  default_transcription_language: 'Standard-Sprache für Transkriptionen',
  contact_us: 'Kontaktieren Sie uns',
  security: 'Sicherheit',
  about_us: 'Über uns',
  download_app: 'App herunterladen',
  download: 'Herunterladen',
  app_store: 'App Store',
  play_store: 'Play Store',
  upload_voice: 'Stimme hochladen',
  no_audio_found: 'Keine Audios gefunden',
  please_input_valid_email_first:
    'Bitte geben Sie zuerst eine gültige E-Mail-Adresse ein',
  handling_audio:
    'Wir transkribieren Ihre Audioaufnahme. Bitte warten Sie einen Moment.',
  welcome_to_leave:
    'Sie können diese Seite verlassen, da wir Ihnen eine E-Mail mit dem Transkriptionsergebnis senden werden.',
  send_your_result:
    'Wir senden Ihnen eine E-Mail mit dem Transkriptionsergebnis.',
  transcribe_audio_thank:
    'Vielen Dank, dass Sie unseren Transkriptionsservice nutzen! Wir arbeiten hart daran, Ihre Audioaufnahme zu transkribieren, und danken Ihnen für Ihre Geduld.',
  transcribe_audio_no_stay:
    'Die Transkription kann etwas dauern, aber keine Sorge, Sie müssen nicht auf dieser Seite bleiben. Wir senden Ihnen die Ergebnisse per E-Mail, sobald sie fertig sind.',
  date: 'Datum',
  sign_up_to_access_more_functions:
    'Registrieren Sie sich, um auf weitere Funktionen zugreifen zu können',
  you_can_use_functions:
    'Sie können nach der Anmeldung die folgenden Funktionen nutzen',
  know_more: 'Mehr erfahren',
  modify_transcription: 'Texttranskription bearbeiten',
  play_audio: 'Audio abspielen',
  content_filters: 'Inhaltsfilter',
  export_diff_type: 'Unterschiedliche Dateitypen exportieren',
  screenshots: 'Screenshots',
  free_trial: 'Kostenlose Testphase',
  pricing: 'Preise',
  faq: 'FAQ',
  contact: 'Kontakt',
  login: 'Anmelden',
  register: 'Registrieren',
  please_login_before_purchase: 'Bitte melden Sie sich vor dem Kauf an',
  no_records_found: 'Keine Einträge gefunden',
  x_minutes: '{x} Minuten',
  confirm: 'Bestätigen',
  notification_mobile_app: 'Mobil-App-Benachrichtigung',
  notification_mobile_app_des:
    'Erhalten Sie eine Benachrichtigung in der mobilen App, wenn das Transkriptionsergebnis bereit ist',
  notification_email: 'E-Mail-Benachrichtigung',
  notification_email_des:
    'Erhalten Sie eine E-Mail-Benachrichtigung, wenn das Transkriptionsergebnis bereit ist',
  video: 'Video',
  or: 'Oder',
  sign_in_with_google: 'Mit Google anmelden',
  sign_in_with_apple: 'Mit Apple anmelden',
  login_method: 'Anmeldeverfahren',
  delete_account: 'Konto löschen',
  delete_account_confirm_3_time:
    'Drücken Sie 3-mal die Löschtaste, um zu bestätigen',
  popup_blocked: 'Pop-up blockiert',
  account_info: 'Kontoinformationen',
  redeem: 'Einlösen',
  redeem_code: 'Code einlösen',
  summary_des1:
    'Der Zusammenfassungsdienst liefert eine Zusammenfassung des Transkriptionsergebnisses. Er kann das Transkriptionsergebnis in ein paar Sätzen zusammenfassen.',
  summary_des2:
    'Deine alte Zusammenfassung wird durch die neue Zusammenfassung ersetzt.',
  consume_transcription_quota: 'Transkriptionskontingent verbrauchen',
  summary: 'Zusammenfassung',
  query: 'Abfrage',
  mins_per_query: '{x} Minuten pro Abfrage',
  free_user_service_once:
    'Kostenlose Benutzer können diesen Dienst nur einmal nutzen.',
  free_user_summary:
    'Kostenlose Benutzer können diesen Dienst nur einmal nutzen. Bitte wechsele zu Pro, um mehr Audioinhalte zusammenzufassen.',
  free_user_query:
    'Kostenlose Benutzer können diesen Dienst nur einmal nutzen. Bitte wechsele zu Pro, um weitere Abfragen zu stellen.',
  information_not_found: 'Informationen nicht gefunden',
  permission_deny: 'Zugriff verweigert',
  please_login_to_check_the_result:
    'Bitte einloggen, um das Ergebnis zu überprüfen',
  speaker_: 'Sprecher {x}',
  audio_length: 'Audio Länge',
  delete_permanently: 'Dauerhaft löschen',
  are_you_sure_to_delete_permanently:
    'Sind Sie sicher, dass Sie dauerhaft löschen möchten?',
  delete_audios_move_to_bin:
    'Die gelöschten Aufzeichnungen werden in den Papierkorb verschoben. Alle Aufzeichnungen im Papierkorb werden nach 30 Tagen gelöscht.',
  payment_id: 'Zahlungs-ID',
  current_plan: 'Aktueller Tarif',
  plan_name: 'Tarifname',
  expire_date: 'Ablaufdatum',
  subscription_method: 'Abonnementmethode',
  payment_method: 'Zahlungsmethode',
  payment_history: 'Zahlungsverlauf',
  amount: 'Betrag',
  invoice: 'Rechnung',
  receipt: 'Quittung',
  link: 'Link',
  edit: 'Bearbeiten',
  back: 'Zurück',
  return: 'Zurückkehren',
  cancel_subscription: 'Abonnement kündigen',
  cancel_subscription_google_play:
    'Auf Ihrem Android-Gerät > Gehen Sie zum Google Play Store > Konto > Zahlungen und Abonnements > Abonnements > SoundType AI > Abonnement kündigen',
  cancel_subscription_apple_store:
    'Auf Ihrem iOS-Gerät > Gehen Sie zu Einstellungen > [Ihr Name] > Abonnements > SoundType AI > Abonnement kündigen',
  delete_account_des: 'Konto löschen wird alle Ihre Daten dauerhaft löschen.',
  delete_account_subscription:
    'Wenn Sie ein Abonnement haben, kündigen Sie bitte zuerst Ihr Abonnement.',
  keep_subscription: 'Abonnement behalten',
  cancel_subscription_des:
    'Ihr Abonnement wird am Ende des aktuellen Abrechnungszyklus ({x}) gekündigt. Nach diesem Datum werden Sie auf den kostenlosen Tarif herabgestuft, und Sie werden keinen Zugang mehr zu den Funktionen des Pro-Tarifs haben:',
  cancel_subscription_feature1: 'Aufnahmen',
  cancel_subscription_feature1_des:
    'Sie werden nur die ersten {x} Minuten Ihrer Aufnahmen ansehen können.',
  cancel_subscription_feature2: 'Transkriptionszeit',
  cancel_subscription_feature2_des:
    'Sie werden nur {x} Minuten pro Monat transkribieren können.',
  cancel_subscription_count:
    'Bitte drücken Sie die Taste {x} Mal, um zu bestätigen',
  next_payment_date: 'Nächstes Zahlungsdatum',
  basic_plan: 'Basisplan',
  resume_subscription: 'Abonnement fortsetzen',
  active: 'Aktiv',
  expired: 'Abgelaufen',
  auto_renewal_clause: 'Automatische Verlängerungsklausel',
  auto_renewal_payment_web:
    'Ihr Abonnement wird automatisch verlängert, es sei denn, Sie kündigen es mindestens 24 Stunden vor dem Ende des aktuellen Zeitraums. Ihr Konto wird innerhalb von 24 Stunden vor dem Ende des aktuellen Zeitraums für die Verlängerung belastet. Sie können Ihre Abonnements verwalten und kündigen, indem Sie zu „Einstellungen“ > „Abrechnung“ gehen.',
  auto_renewal_terms_and_conditions:
    'Durch das Abonnieren stimmen Sie unseren Allgemeinen Geschäftsbedingungen und Datenschutzrichtlinien zu.',
  payment: 'Zahlung',
  monthly: 'Monatlich',
  annual: 'Jährlich',
  minutes_per_month: '{x} Minuten/Monat',
  buy_now: 'Jetzt kaufen',
  yearly: 'Jährlich',
  yearly_save: 'Spare bis zu {x}%',
  pro_plan: 'Pro',
  business_plan: 'Business',
  enterprise_plan: 'Unternehmen',
  everything_in_basic: 'Alles in Basic, plus',
  everything_in_pro: 'Alles in Pro, plus',
  everything_in_business: 'Alles in Business, plus',
  ai_chat_x: 'KI-Chat x {x}',
  ai_summary_x: 'KI-Zusammenfassung x {x}',
  max_x_minutes_per_transcription: 'Max {x} Minuten pro Transkription',
  basic_des2: 'Sprecheridentifikation',
  basic_des3: 'Audio-/Videodatei-Transkription',
  basic_des4: 'Transkripte überprüfen & bearbeiten',
  pro_des2: 'Prioritätswarteschlange',
  pro_des3: 'Export im SRT-Format',
  pro_des4: 'Unbegrenzte KI-Zusammenfassung',
  business_des1: 'Nutzungsanalysebericht',
  business_des2: 'Kollaborativer Arbeitsbereich mit Teammitgliedern',
  business_des3: 'Transkripte innerhalb Ihres Teams teilen und bearbeiten.',
  business_des4: 'Freigabeberechtigungen steuern',
  coming_soon: 'Demnächst',
  get_started: 'Loslegen',
  bill_annually_x: 'Jährlich {x} abgerechnet',
  no_credit_card_required: 'Keine Kreditkarte erforderlich',
  save_x: 'Spare {x}%',
  price_per_month: ' / Monat',
  not_available: 'Nicht verfügbar',
  pricing_title: 'Preise',
  upgrade: 'Upgrade',
  ask_question_to_your_audio: 'Stellen Sie Ihrer Audio eine Frage',
  monthly_minutes: 'Monatliche Minuten',
  payment_interval: 'Zahlungsintervall',
  annually: 'Jährlich',
  billing: 'Abrechnung',
  subscription_plan: 'Abo',
  please_cancel_your_current_subscription_first:
    'Bitte kündigen Sie zuerst Ihr aktuelles Abonnement',
  please_cancel_your_current_subscription_first_des:
    'Um Ihren Abonnementplan zu ändern, müssen Sie zuerst Ihr aktuelles Abonnement kündigen.',
  cancel_subscription_instruction:
    'Gehen Sie zu Einstellungen > Abrechnung > Abonnement kündigen',
  website_version: 'Webseitenversion',
  change_to_annual_plan: 'Zum Jahresplan wechseln',
  update_annual_mobile_platform_reminder: 'Erinnerung',
  update_annual_mobile_platform_reminder_des:
    'Bitte beachten Sie, dass Sie Ihr aktuelles Abonnement auf {x} kündigen müssen, bevor Sie zum Jahresplan wechseln. Andernfalls werden Sie doppelt belastet.',
  i_understand: 'Ich verstehe',
  no_credit: 'Nicht genug Minuten',
  re_submit: 'Erneut einreichen',
  loading_audio: 'Audio wird geladen',
  your_password: 'Dein Passwort',
  reset_password_email_sent:
    'Eine E-Mail wurde an Ihre E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihr Postfach, um Ihr Passwort zurückzusetzen.',
  home: 'Startseite',
  title_a_z: 'Titel A-Z',
  title_z_a: 'Titel Z-A',
  older_first: 'Älter zuerst',
  newest_first: 'Neueste zuerst',
  table_item_count: '{x} - {y} von {z} Einträgen',
  starred: 'Markiert',
  search: 'Suchen',
  create_folder: 'Ordner erstellen',
  folder_name: 'Ordnername',
  create: 'Erstellen',
  export_preview: 'Vorschau exportieren',
  combine_segments: 'Segmente kombinieren',
  export_audio_only:
    'Sie können nur Audio exportieren, wenn das Audio transkribiert wird.',
  transcribe_remaining_x_minutes: 'Verbleibende {x} Minuten transkribieren',
  x_minutes_remain_be_transcribed: '{x} Minuten verbleiben zur Transkription',
  free_member_limited_transcribe_view:
    'Nur die ersten {x} Minuten werden transkribiert und für kostenlose Mitglieder einsehbar',
  upgrade_plan_to_transcribe:
    'Aktualisieren Sie Ihren Plan, um das gesamte Audio zu transkribieren',
  only_paid_user_can_use:
    'Nur zahlende Benutzer können die verbleibenden Minuten transkribieren',
  converting_to_audio: 'Wird in Audio umgewandelt',
  move_to_folder: 'In Ordner verschieben',
  move_audios_to_folder:
    'Die ausgewählten Audiodateien in den Ordner verschieben',
  please_select_folder: 'Bitte wählen Sie einen Ordner',
  empty_folder: 'Leerer Ordner',
  move: 'Verschieben',
  user_not_found:
    'Benutzer nicht gefunden. Der angegebene Benutzer konnte nicht gefunden werden. Bitte überprüfen Sie die Angaben und versuchen Sie es erneut oder wenden Sie sich an den Support, falls Sie weitere Unterstützung benötigen.',
}
