export const pt = {
  sign_in: 'Iniciar sessão',
  email: 'Email',
  password: 'Senha',
  i_agree_to_toc: 'Eu concordo com os termos e condições.',
  terms_and_conditions: 'Termos e condições',
  privacy_policy: 'Política de privacidade',
  already_have_an_account: 'Já tem uma conta?',
  sign_up: 'Registar',
  dont_have_an_account: 'Não tem uma conta?',
  forgot_password: 'Esqueceu-se da senha?',
  email_already_in_use: 'Email já em uso',
  weak_password: 'Senha fraca',
  too_many_attempts: 'Muitas tentativas',
  invalid_email_or_password: 'Email ou senha inválidos',
  user_already_registered: 'Usuário já registrado',
  unexpected_error: 'Erro inesperado',
  audios: 'Áudios',
  name: 'Nome',
  auto: 'Automático',
  auto_detect: 'Detectar automaticamente',
  speaker_num: 'Número de falantes',
  speaker_num_reason:
    'Se você nos informar o número de falantes, forneceremos um resultado de transcrição melhor.',
  audio_language: 'Idioma do áudio',
  your_email: 'Seu email',
  email_result: 'Enviaremos um email com o resultado da transcrição.',
  provide_language:
    'Se você informar o idioma do áudio, forneceremos um resultado de transcrição melhor.',
  upload_audio: 'Enviar Áudio',
  upload_file: 'Enviar Arquivo',
  process_audio: 'Processando seu arquivo',
  process_audio_des:
    'Aguarde enquanto seu áudio está sendo transcrito. Isso pode levar alguns minutos.',
  uploading: 'Enviando',
  pending: 'Pendente',
  uploaded: 'Enviado',
  failed: 'Falha',
  status: 'Status',
  language: 'Idioma',
  speakers: 'Falantes',
  duration: 'Duração',
  done: 'Concluído',
  close: 'Fechar',
  queuing: 'Em fila',
  created_at: 'Criado em',
  records: 'Registros',
  transcribe: 'Transcrever',
  delete: 'Excluir',
  processing: 'Processando',
  from_youtube: 'Do YouTube',
  upload_files: 'Enviar arquivos',
  place_youtube_link_here: 'Insira o link do YouTube aqui',
  submit: 'Enviar',
  email_notification:
    'O tempo de conclusão depende do comprimento e da complexidade do arquivo de áudio. Geralmente, você receberá a transcrição em algumas horas. Notificaremos você por email assim que estiver pronta.',
  filters: 'Filtros',
  filter: 'Filtrar',
  replace: 'Substituir',
  share_and_export: 'Compartilhar e Exportar',
  replace_all: 'Substituir tudo',
  next: 'Próximo',
  previous: 'Anterior',
  find: 'Localizar',
  replace_with: 'Substituir por',
  x_of_x: '{current} de {total}',
  scroll_lock: 'Bloquear rolagem',
  tags: 'Tags',
  bookmark: 'Favorito',
  audio: 'Áudio',
  cancel: 'Cancelar',
  export: 'Exportar',
  export_format: 'Formato de exportação',
  copy_text: 'Copiar texto',
  monologue: 'Monólogo',
  include_timestamps: 'Incluir marcações de tempo',
  include_speaker_names: 'Incluir nomes dos falantes',
  combine_same_speaker: 'Combinar segmentos do mesmo falante',
  export_mode: 'Modo de exportação',
  no_preview: 'Sem visualização',
  copy: 'Copiar',
  copied: 'Copiado',
  unlock_pro_feature:
    'Para desbloquear esse recurso, faça upgrade para o plano de assinatura "Pro".',
  pro_features: 'Recursos Pro',
  pro_minutes: '1800 minutos / mês',
  pro_interact_with_audio: 'Interagir com o áudio',
  pro_interact_with_audio_des: 'Fazer perguntas ao seu áudio',
  pro_summary: 'Resumo de IA',
  pro_summary_des: 'Resumir o seu áudio',
  pro_priority: 'Fila de prioridade',
  pro_priority_des: 'Processar áudio com prioridade',
  pro_multi_speakers: 'Reconhecimento de múltiplos falantes',
  pro_multi_speakers_des: 'Detecta e rotula com precisão diferentes falantes',
  redirect_to_stripe:
    'Você será redirecionado para o Stripe para concluir o pagamento.',
  x_per_month: '{x} / Mês',
  x_per_year: '{x} / Ano',
  trash: 'Lixo',
  dashboard: 'Painel de controle',
  logout: 'Sair',
  are_you_sure_to_delete:
    'Tem certeza de que deseja excluir os registros selecionados?',
  restore: 'Restaurar',
  are_you_sure_to_restore:
    'Tem certeza de que deseja restaurar os registros selecionados?',
  deleted_at: 'Excluído em',
  free_member_limited_view:
    'Membros gratuitos terão acesso apenas aos primeiros {x} minutos do resultado da transcrição.',
  hidden_segment: '{x} segmentos estão ocultos após {y} minutos.',
  settings: 'Configurações',
  subscription: 'Assinatura',
  display_language: 'Idioma da interface',
  default_transcription_language: 'Idioma padrão da transcrição',
  contact_us: 'Contate-nos',
  security: 'Segurança',
  about_us: 'Sobre nós',
  download_app: 'Baixar aplicativo',
  download: 'Baixar',
  app_store: 'App Store',
  play_store: 'Play Store',
  upload_voice: 'Enviar voz',
  no_audio_found: 'Nenhum áudio encontrado',
  please_input_valid_email_first: 'Por favor, insira um email válido primeiro',
  handling_audio:
    'Estamos transcrevendo seu áudio. Por favor, aguarde um momento.',
  welcome_to_leave:
    'Você pode sair desta página, pois enviaremos um email com o resultado da transcrição.',
  send_your_result: 'Enviaremos um email com o resultado da transcrição.',
  transcribe_audio_thank:
    'Obrigado por usar nosso serviço de transcrição! Estamos trabalhando duro para transcrever seu áudio e agradecemos sua paciência.',
  transcribe_audio_no_stay:
    'Sua transcrição pode demorar um pouco, mas não se preocupe, você não precisa ficar nesta página. Enviaremos os resultados por email assim que estiverem prontos.',
  date: 'Data',
  sign_up_to_access_more_functions: 'Registre-se para acessar mais funções',
  you_can_use_functions: 'Você pode usar as seguintes funções após fazer login',
  know_more: 'Saiba mais',
  modify_transcription: 'Modificar transcrição de texto',
  play_audio: 'Reproduzir áudio',
  content_filters: 'Filtros de conteúdo',
  export_diff_type: 'Exportar diferentes tipos de arquivos',
  screenshots: 'Capturas de tela',
  free_trial: 'Teste gratuito',
  pricing: 'Preços',
  faq: 'Perguntas frequentes',
  contact: 'Contato',
  login: 'Entrar',
  register: 'Registrar',
  please_login_before_purchase: 'Faça login antes de fazer a compra',
  no_records_found: 'Nenhum registro encontrado',
  x_minutes: '{x} Minutos',
  confirm: 'Confirmar',
  notification_mobile_app: 'Notificação de Aplicativo Móvel',
  notification_mobile_app_des:
    'Receba uma notificação no aplicativo móvel quando o resultado da transcrição estiver pronto',
  notification_email: 'Notificação por Email',
  notification_email_des:
    'Receba uma notificação por e-mail quando o resultado da transcrição estiver pronto',
  video: 'Video',
  or: 'Ou',
  sign_in_with_google: 'Entrar com o Google',
  sign_in_with_apple: 'Entrar com a Apple',
  login_method: 'Método de Login',
  delete_account: 'Excluir Conta',
  delete_account_confirm_3_time:
    'Pressione o botão de exclusão 3 vezes para confirmar',
  popup_blocked: 'Bloqueio de Pop-up',
  account_info: 'Informações da Conta',
  redeem: 'Resgatar',
  redeem_code: 'Resgatar código',
  summary_des1:
    'O serviço de resumo fornece um resumo do resultado da transcrição. Ele pode resumir o resultado da transcrição em algumas frases.',
  summary_des2: 'Seu antigo resumo será substituído pelo novo resumo.',
  consume_transcription_quota: 'Consumir cota de transcrição',
  summary: 'Resumo',
  query: 'Consulta',
  mins_per_query: '{x} minutos por consulta',
  free_user_service_once:
    'Usuários gratuitos só podem usar este serviço uma vez.',
  free_user_summary:
    'Usuários gratuitos só podem usar este serviço uma vez. Faça upgrade para o plano Pro para resumir mais áudios.',
  free_user_query:
    'Usuários gratuitos só podem usar este serviço uma vez. Faça upgrade para o plano Pro para fazer mais consultas.',
  information_not_found: 'Informação não encontrada',
  permission_deny: 'Permissão negada',
  please_login_to_check_the_result:
    'Por favor, faça login para verificar o resultado',
  speaker_: 'Palestrante {x}',
  audio_length: 'Duração do Áudio',
  delete_permanently: 'Excluir Permanentemente',
  are_you_sure_to_delete_permanently:
    'Tem certeza de que deseja excluir permanentemente?',
  delete_audios_move_to_bin:
    'Os registros excluídos serão movidos para a lixeira. Todos os registros na lixeira serão excluídos após 30 dias.',
  payment_id: 'ID do pagamento',
  current_plan: 'Plano atual',
  plan_name: 'Nome do plano',
  expire_date: 'Data de expiração',
  subscription_method: 'Método de assinatura',
  payment_method: 'Método de pagamento',
  payment_history: 'Histórico de pagamento',
  amount: 'Quantia',
  invoice: 'Fatura',
  receipt: 'Recibo',
  link: 'Link',
  edit: 'Editar',
  back: 'Voltar',
  return: 'Retornar',
  cancel_subscription: 'Cancelar assinatura',
  cancel_subscription_google_play:
    'No seu dispositivo Android > Acesse a Google Play Store > Conta > Pagamentos e assinaturas > Assinaturas > SoundType AI > Cancelar assinatura',
  cancel_subscription_apple_store:
    'No seu dispositivo iOS > Acesse Ajustes > [seu nome] > Assinaturas > SoundType AI > Cancelar assinatura',
  delete_account_des:
    'Deletar a conta irá deletar todos os seus dados permanentemente.',
  delete_account_subscription:
    'Se você tem uma assinatura, por favor, cancele sua assinatura primeiro.',
  keep_subscription: 'Manter Assinatura',
  cancel_subscription_des:
    'Sua assinatura será cancelada no final do ciclo de cobrança atual ({x}). Após essa data, você será rebaixado para o plano gratuito, e você não poderá mais acessar as funcionalidades do plano Pro:',
  cancel_subscription_feature1: 'Gravações',
  cancel_subscription_feature1_des:
    'Você só poderá visualizar os primeiros {x} minutos de suas gravações.',
  cancel_subscription_feature2: 'Tempo de transcrição',
  cancel_subscription_feature2_des:
    'Você só poderá transcrever {x} minutos por mês.',
  cancel_subscription_count:
    'Por favor, pressione o botão {x} vezes para confirmar',
  next_payment_date: 'Data do próximo pagamento',
  basic_plan: 'Plano Básico',
  resume_subscription: 'Retomar assinatura',
  active: 'Ativo',
  expired: 'Expirado',
  auto_renewal_clause: 'Cláusula de renovação automática',
  auto_renewal_payment_web:
    'Sua assinatura será renovada automaticamente, a menos que você a cancele pelo menos 24 horas antes do final do período atual. Sua conta será cobrada pela renovação dentro de 24 horas antes do final do período atual. Você pode gerenciar e cancelar suas assinaturas acessando "Configurações" > "Cobrança".',
  auto_renewal_terms_and_conditions:
    'Ao assinar, você concorda com nossos Termos e Condições e Política de Privacidade.',
  payment: 'Pagamento',
  monthly: 'Mensal',
  annual: 'Anual',
  minutes_per_month: '{x} minutos/mês',
  buy_now: 'Comprar Agora',
  yearly: 'Anual',
  yearly_save: 'Economize até {x}%',
  pro_plan: 'Pro',
  business_plan: 'Negócios',
  enterprise_plan: 'Empresarial',
  everything_in_basic: 'Tudo no Básico, mais',
  everything_in_pro: 'Tudo no Pro, mais',
  everything_in_business: 'Tudo nos Negócios, mais',
  ai_chat_x: 'Chat de IA x {x}',
  ai_summary_x: 'Resumo de IA x {x}',
  max_x_minutes_per_transcription: 'Máximo de {x} minutos por transcrição',
  basic_des2: 'Identificação do falante',
  basic_des3: 'Transcrição de arquivo de áudio/vídeo',
  basic_des4: 'Revisar & editar transcrições',
  pro_des2: 'Fila Prioritária',
  pro_des3: 'Exportar no formato SRT',
  pro_des4: 'Resumo de IA ilimitado',
  business_des1: 'Relatório de análise de uso',
  business_des2: 'Espaço de trabalho colaborativo com membros da equipe',
  business_des3: 'Compartilhe e edite transcrições com sua equipe.',
  business_des4: 'Controle as permissões de compartilhamento',
  coming_soon: 'Em Breve',
  get_started: 'Começar',
  bill_annually_x: 'Faturado {x} anualmente',
  no_credit_card_required: 'Não é necessário cartão de crédito',
  save_x: 'Economize {x}%',
  price_per_month: ' / mês',
  not_available: 'Não disponível',
  pricing_title: 'Preços',
  upgrade: 'Atualizar',
  ask_question_to_your_audio: 'Faça uma pergunta ao seu áudio',
  monthly_minutes: 'Minutos mensais',
  payment_interval: 'Intervalo de pagamento',
  annually: 'Anualmente',
  billing: 'Faturamento',
  subscription_plan: 'Plano',
  please_cancel_your_current_subscription_first:
    'Por favor, cancele sua assinatura atual primeiro',
  please_cancel_your_current_subscription_first_des:
    'Para mudar seu plano de assinatura, você precisa cancelar sua assinatura atual primeiro.',
  cancel_subscription_instruction:
    'Vá para Configurações > Faturamento > Cancelar Assinatura',
  website_version: 'Versão do Site',
  change_to_annual_plan: 'Mudar para o Plano Anual',
  update_annual_mobile_platform_reminder: 'Lembrete',
  update_annual_mobile_platform_reminder_des:
    'Por favor, note que você precisa cancelar sua assinatura atual em {x} antes de mudar para o plano anual. Caso contrário, você será cobrado duas vezes.',
  i_understand: 'Eu entendo',
  no_credit: 'Minutos insuficientes',
  re_submit: 'Reenviar',
  loading_audio: 'Carregando áudio',
  your_password: 'Sua senha',
  reset_password_email_sent:
    'Um e-mail foi enviado para o seu endereço de e-mail. Verifique sua caixa de entrada para redefinir sua senha.',
  home: 'Início',
  title_a_z: 'Título A-Z',
  title_z_a: 'Título Z-A',
  older_first: 'Mais antigo primeiro',
  newest_first: 'Mais recente primeiro',
  table_item_count: '{x} - {y} de {z} itens',
  starred: 'Estrelado',
  search: 'Pesquisar',
  create_folder: 'Criar pasta',
  folder_name: 'Nome da pasta',
  create: 'Criar',
  export_preview: 'Exportar visualização',
  combine_segments: 'Combinar segmentos',
  export_audio_only:
    'Você só pode exportar áudio quando o áudio estiver sendo transcrito.',
  transcribe_remaining_x_minutes: 'Transcrever os {x} minutos restantes',
  x_minutes_remain_be_transcribed:
    '{x} minutos restantes para serem transcritos',
  free_member_limited_transcribe_view:
    'Somente os primeiros {x} minutos serão transcritos e visualizados para membros gratuitos',
  upgrade_plan_to_transcribe:
    'Atualize seu plano para transcrever o áudio completo',
  only_paid_user_can_use:
    'Somente usuários pagos podem transcrever os minutos restantes',
  converting_to_audio: 'Convertendo para áudio',
  move_to_folder: 'Mover para a pasta',
  move_audios_to_folder: 'Mover os áudios selecionados para a pasta',
  please_select_folder: 'Por favor, selecione uma pasta',
  empty_folder: 'Pasta vazia',
  move: 'Mover',
  user_not_found:
    'Usuário não encontrado. O usuário especificado não pôde ser localizado. Verifique os detalhes e tente novamente, ou entre em contato com o suporte se precisar de mais assistência.',
}
